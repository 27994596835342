import React, { Fragment } from 'react';
import { Banner, Footer, Map, Navbar } from 'src/components/common';

import SEO from 'src/components/seo';

const ContactPage = () => {
  return (
    <Fragment>
      <Navbar />
      <SEO title="Contact" />
      <Map />
      <Banner />
      <Footer />
    </Fragment>
  );
};

export default ContactPage;
